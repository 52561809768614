.tb-0 {
  padding: 0px 20px;
  /* height: 90%; */
  height: 74vh;
}
.MuiDataGrid-columnSeparator {
  display: none !important;
}
.tb-0 .MuiDataGrid-columnHeaderTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
}

.bold-text .MuiDataGrid-columnHeaderTitle {
 font-weight: 600;
}
.cell-padding {
  padding-top: 1.41vh !important;
  padding-bottom: 1.6vh !important;
}

.bold-text{
  background-color: #d3d3d330;
}
.bold-text .cell-padding .MuiDataGrid-cellContent{
  font-weight: 500;
}
.topAlign{
  display: flex;
  align-items: flex-start !important;
}

.mainPage {
  overflow-y:scroll;
  height: 100vh;
  margin-top: 32px;
  padding: 0 32px 0 32px;
}

/* .MuiDataGrid-row:hover {
  background-color: white !important;
} */

.my-row {
  /* padding: 20px 0px 20px 0px; */
}

.tb-0 .MuiDataGrid-cellContent {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
  word-break: break-word;
}

.tb-0 .MuiDataGrid-columnHeader:focus,
.css-1usso8h-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
  color: #1d2026;
}

.MuiTablePagination-toolbar{
  color: #1d2026;
}

