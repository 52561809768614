.Manage-Devices-backIcon-pd {
  margin-right: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 9.92px 13.83px 10px 14.33px;
}

@media (max-width:775px) {
  .Manage-Devices-backIcon-pd {
    padding: 9.92px 5px 10px 0px;
    height: 14px;
  }
}

.headingContainer {
  display: flex;
  align-items: center;
  margin-right: auto;
  padding-right: 20px;
}

.headingDarkText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  color: #1D2026;
}
.wordBreak{
  word-break: break-all;
}
.headingLightText {
  font-weight: 400 !important;
  padding-right: 5px;
}

@media (max-width:980px) {
  /* .headingDarkText {
    font-size: 16px;
  }
  .iconHeight{
    height: 16px !important;
  } */
}

@media (max-width:775px) {
  /* .headingDarkText {
    font-size: 14px;
    letter-spacing: 0.5px;
  }
  .iconHeight{
    height: 15px !important;
  } */
}

@media (max-width:945px) {
  .orderDeviceBtnText {
    font-size: 12px;
    letter-spacing: 0;
  }
}

@media (max-width:775px) {
  .orderDeviceBtnText {
    font-size: 10px;
    letter-spacing: 0;
  }
}

@media (max-width:775px) {
  .orderDeviceBtnText {
    font-size: 10px;
    letter-spacing: 0;
  }
}

@media (max-width:1199px) {
  .orderDeviceBtn {
    padding: 6px 12px;
  }
}

.headerTextSpan {
  display: flex;
}

.tableCellDataStyle{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1D2026;
}

/* css for Order New Device Modal */

.orderDeviceModalCancleBtn {
  padding-top: 9px;
  padding-right: 10px;
  margin-left: auto;
}

.orderDeviceModalCancleBtn .MuiIconButton-root {
  padding: 0px;
}

.OrderDeviceFormContainer {
  padding: 27px 100px 49px 100px;
}

.orderDeviceModalHeading {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1D2026;
  padding-bottom: 20px;
}

.orderDeviceModalDivider {
  border: 1px solid #E2E3E6;
}

.orderDeviceModalfirstGridItem {
  padding-top: 20px;
}

.orderDeviceModalGridItem {
  padding-top: 34px;
}


/* css for Review and Order Modal */

.orderAddressTextP {
  padding-bottom: 15px;
}

.orderAddressStyle {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #3f96dd;
  padding-left: 22px;
}

.orderAddressDetailStyle {
  padding-left: 42px;
  word-break: break-word;
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 20px;
letter-spacing: 1px;
text-align: left;

}

.PaymentMethodCardContainer {
  padding-left: 42px;
}

.PaymentMethodCard {
  /* padding: 20px 30px; */
  background: #FAFAFA;
  border: 1px solid #C6C8CA;
  border-radius: 5px;
}

.PaymentMethodcardStartHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5C5F67;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.PaymentMethodCentercardHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5C5F67;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PaymentMethodEndcardHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5C5F67;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.PaymentMethodstartContent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1D2026;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.PaymentMethodCentreContent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #1D2026;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PaymentMethodEndContent {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 1px;
  color: #1D2026;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.PaymentMethodCardDivider {
  margin: 20px 0px;
}

.reviewOrderModal .MuiPaper-root.MuiDialog-paper {
  max-width: 73.3vw !important;
  width: 73.3vw !important;
}



.TaxTextStyle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 1px;
  color: #1D2026;
}

.TotalTaxTextStyle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 1px;
  color: #1D2026;

}

.TaxContainer {
  display: flex;
  justify-content: flex-end;
}

.taxb-m-10 {
  margin-bottom: 10px;
}

.paymentMethodNextBtn {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  margin-left: auto;
}
.reviewOrderNxtBtn{
  min-width: 99px;
}

/* css for order Conformation modal */

.OrderConformationContainer {
  padding: 8% 14%;
  text-align: center;
}

.OrderConformationModal .MuiPaper-root.MuiDialog-paper {
  width: 744px;
  max-width: 744px;
  overflow-y: scroll;
  background: #FFFFFF;
  border: 1px solid #C5C9D0;
  border-radius: 15px;
}

.orderConfImg {
  padding-bottom: 9.4%;
}
.pendingOrderConfImg {
  padding-bottom: 20px;
}

.orderConfPrimaryText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1px;
  color: #1D2026;
  padding-bottom: 5px;
}

.orderConfSecondaryText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  color: #1D2026;
  padding-bottom: 17px;
  justify-content: center;
}
.OrderConfBtn{
  width: 236px;
}