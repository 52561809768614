.textAlignEnd{
    text-align: end;
}
.mainGridContainer{
    padding: 14px ;
    padding-bottom:0 ;
    
}
.mainGridContainer2{
    padding-top:4px !important ;
    padding: 14px;
    padding-bottom:4px ;  
}

.noCommData{
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #5c5f67;
    justify-content: center;
    padding: 20px;
}


.gridConatiner1{
    /* height: 250px; */
    display: flex;
    padding-bottom: 15px;
    justify-content: center;
}
.lastLink{
    padding-bottom: 0px;

}
.gridContainer2{
   display: flex;
   justify-content: center;
}
.pt-10{
    padding-top: 10px;
}
.accountHeading{
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 2px;
    /* text-align: left; */
    position: sticky;
    display: inline-flex;
    align-items: center;
}

.mainHeading{
    position: sticky;
    top: 0px;
    background-color: #F0F1F4;
    box-shadow: 2px 0px #F0F1F4;
}

.accountSubText {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;

}
.subscriberColumn1{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    color: #1D2026;    
}
.settingSubHeading {
    font-family: Roboto;
    font-size: 1.25em;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: left;
    color: #1D2026;
    text-transform: capitalize;
}
.subscriberDetail{
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    color: #1D2026;
}
.capitalise{
    text-transform: capitalize;
}
.subscriberColumn2 {
    color: #5C5F67;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;

}
.accountSettingbackIcon{
    cursor: pointer;
    margin-right: 1.8vw;
}

.subscriptionColumn3{
    font-size: 14px;
    color: #007EB0;
    cursor: pointer;
    text-align: end;
    display: flex;
    justify-content: flex-end;
}
.accountSetingModal .MuiPaper-root.MuiDialog-paper{
    padding-top: 4vh;
    padding-bottom: 4vh;
    min-width: 36.458vw;
}
.checkPos{
    display: flex;
    justify-content: center;
    align-items: center;
}
.communicationAlign{
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalBtn{
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 5px;
}
.modalContent{
    padding: 32px 20px;
}
.resendOtp{
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    color: #007EB0;
}
.fw-700{
    font-weight: 700;
}
.tooltipParagrah{
    margin: 0px;
}

.billingAddPadding.MuiGrid-item {
    padding-top: 0px;
}

.newPasswordInfo{
    position: relative;
    left: 120px;
    width: fit-content;
    cursor: pointer;
    top: 4px;
}

.visa{
    color: blue;
    height: 25px;
}
.mastercard{
    color: orangered;
    height: 25px;
}
.payment{
    color: yellowgreen;
}
.showDisplay{
    display: flex;
}
.cardDetail{
    display: flex;
    flex-direction: row;
}

@media (max-width:1199px){
    .widthPara{
        width: fit-content;
    }
}
@media (max-width:1100px){
    .accountSetingModal .MuiPaper-root.MuiDialog-paper{
        min-width: 75vw;
    }
}