.tableRenderCellText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
}
.redText {
  color: #d70015;
}

.userStatusActive {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #248a3d;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  background: #c6ffc5;
  border-radius: 4px;
}

.userStatusDeactive {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #d70015;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  background: #ffedea;
  border-radius: 3px;
}

.userStatusInvited {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #b25000;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  background: #c6ffc5;
  border-radius: 4px;
  background: #ffdbc9;
  border-radius: 3px;
}

.messageTextField
  .MuiInputBase-multiline.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  box-shadow: 0px 0px 5px 2px rgba(63, 150, 221, 0.5);
  border-color: #3f96dd;
}

.messageTextField .MuiInputBase-multiline textarea {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
}
.sendMsgTitle {
  padding: 0px 24px;
  margin-bottom: 1.87vh;
  color: #1d2026;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: Roboto;
  font-size: 20px;
}

.sendMsgHeaderPhoneno {
  line-height: 10px;
  font-size: 14px;
}
.textCapital {
  text-transform: capitalize;
}

.filterMenuStyle {
  max-height: none !important;
}

.addEditBenificiary {
  padding-bottom: 5vh;
  padding-top: 2vh;
}

.benificiaryModal .MuiPaper-root.MuiDialog-paper {
  padding-top: 4vh;
}

.textSlice {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 380px;
}
.addFamilyModal.accountSetingModal .MuiPaper-root.MuiDialog-paper {
  max-width: 36.458vw;
}

.genderRadioGroup{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
}