.MuiTooltip-tooltip {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    color: #5C5F67;
    background: #FFFFFF;
    border: 1px solid #3F96DD;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
}

.MuiTooltip-arrow::before {
    border: 1px solid #3F96DD;
    background-color: #fff;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipArrow.MuiTooltip-tooltipPlacementTop{
    margin-bottom: 8px;
}

