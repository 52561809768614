.planDisplayCard {
  border: 1px solid #c5c9d0;
  box-shadow: 0px 5px 5px 0px #0000001a;
  border-radius: 15px;
  padding-bottom: 24px;
  min-height: 572px;
}

.highlightedCard {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

}

.planNameDisplay {
  padding: 18px 20px 18px 30px;
  background: #e8f1ff;
  color: #1D2026;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  /* text-align: center; */

}

.taggedPlanName {
    background: #004a78;
    color: #ffffff;

}
.textAlignCenter {
  text-align: center;
}
.planAmount {
  font-family: Roboto;
  font-size: 42px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 1px;
}

.planCurrency {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* padding-bottom: 12px; */
}

.planFrequency {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* padding-bottom: 12px; */
}

.planSubDetails {
  background: #e8f1ff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  color: #233c78;
  height: 40px;
  padding: 10px 0px;
}

.planDetailsBullets {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
}

.planDetailsBullets p{
    margin: 0
}

.planDetailsBullets ul{
    margin: 0;
    padding: 0;
    /* padding-inline-start: 100px; */
    /* list-style-image:  url( "../../../../assets/icons/checkCircle.png"); */
    list-style-type: none;
}

.planDetailsBullets h1{
  margin-top: 0;
}

.planDetailsBullets h2{
  margin-top: 0;
}
.planDetailsBullets h3{
  margin-top: 0;
}

.planDetailsBullets h4{
  margin-top: 0;
}

.planDetailsBullets ul li{
  position: relative; /* Position context for the pseudo-element */
  padding-left: 30px; /* Add space for the marker */
  margin-bottom: 12px;
}

.planDetailsBullets ul li::before {
  content: ''; /* Necessary for the pseudo-element */
            position: absolute; /* Position it relative to the list item */
            top: 50%; /* Center vertically */
            left: 0px; /* Align to the left */
            transform: translateY(-50%); /* Center vertically */
            width: 16px; /* Set the desired width */
            height: 16px; /* Set the desired height */
            background-image: url("../../../../assets/icons/checkCircle.png"); /* URL of the marker image */
            background-size: cover;
}

.planCSS {
    align-items: center;
    display: flex;
}

.check{
    width:24px;
    height:24px;
    margin-right: 12px; 
}

.planTag{
    background: #41A353;
border-radius: 5px;
color: #ffffff;
padding: 6px 8px;
font-family: DM Sans;
font-size: 14px;
font-weight: 700;
text-align: center;
position: relative;
top: 2px;
left: 30px;

}

.hidden{
  visibility: hidden;
}


.highlighted{
  background: #3f96dd;
  color: #ffffff;
}

.chip {
  margin: 0 5px;
  border-radius: 5px !important;
  font-size: 12px !important;
  font-family: Roboto!important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 1px !important;
}
