@import url("~@fontsource/roboto/index.css");
@import url("~@fontsource/roboto/100.css");
@import url("~@fontsource/roboto/300.css");
@import url("~@fontsource/roboto/400.css");
@import url("~@fontsource/roboto/500.css");
@import url("~@fontsource/roboto/700.css");
@import url("~@fontsource/roboto/900.css");
@import url("~@fontsource/open-sans/300.css");
@import url("~@fontsource/open-sans/500.css");
@import url("~@fontsource/open-sans/600.css");
@import url("~@fontsource/open-sans/700.css");
@import url("~@fontsource/open-sans/800.css");
@import url("~@fontsource/dm-sans/100.css");
@import url("~@fontsource/dm-sans/300.css");
@import url("~@fontsource/dm-sans/400.css");
@import url("~@fontsource/dm-sans/500.css");
@import url("~@fontsource/dm-sans/700.css");
@import url("~@fontsource/dm-sans/800.css");


html,
body {
  margin: 0;
  height: 100vh;
  font-family: Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.innerMainCard {
  margin-top: 2.8vh !important;
  min-height: 822px;
}

.f-14 {
  font-size: 14px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-24 {
  font-size: 24px !important;
}
.slice {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.countFonts {
    font-size: 18px;
}

@media only screen and (max-width: 998px) {
  .f-14 {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .f-14 {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1660px) {
  .f-14 {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 998px) {
  .f-10 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .f-10 {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1660px) {
  .f-10 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 998px) {
  .f-12 {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .f-12 {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1660px) {
  .f-12 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 998px) {
  .f-20 {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .f-20 {
    font-size: 20 !important;
  }
}

@media only screen and (min-width: 1660px) {
  .f-20 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 998px) {
  .f-16 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .f-16 {
    font-size: 16 !important;
  }
}

@media only screen and (min-width: 1660px) {
  .f-16 {
    font-size: 21px;
  }
}

@media only screen and (max-width: 998px) {
  .f-18 {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 1280px) {
  .f-18 {
    font-size: 18px !important;
  }
}

@media only screen and (min-width: 1660px) {
  .f-18 {
    font-size: 18px;
  }
}
