

.normalText{
    min-height: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #1D2026;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 0px 20px;
    text-align: center;
}

.normalTextWithPad{
    padding: 15px 10px;

}

.buttonsDisplay{
    display: flex;
    justify-content: space-evenly;
    min-height: 51px;
    padding: 0 !important;
}

.confirmbtn{
    font-size: 16px;
    font-weight: 700;
    color: #8F909A;
    width: 100%;
    cursor: pointer;
}
.confirmbtn:hover{
    color: #3F96DD;
    background-color: #fff !important;
}
