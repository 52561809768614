.notification-Container {
    margin-right: auto;
}

.notificationHeading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    color: #1D2026;
}

@media screen and (max-width: 899px) {
    .notificationHeading{
        font-size: 22px !important;
    }
  }

  @media screen and (max-width: 779px) {
    .notificationHeading{
        font-size: 20px !important;
    }
  }

  @media screen and (max-width: 749px) {
    .notificationHeading{
        font-size: 24px !important;
    }
  }

  @media screen and (max-width: 599px) {
    .notificationDatepicker{
        width: 100% !important;
    }
  }

.notification-main-title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 2px;
    color: #1d2026;
  }

  .notificationBackIcon{
    height: 24px;
  }
  @media screen and (max-width: 699px) {
    .notification-main-title{
        font-size: 17px !important;
    }
    .notificationBackIcon{
        height: 17px !important;
    }
  }
