.loader{
    animation: spin 4s infinite linear;
    -webkit-animation: spin 4s infinite linear;
}

.loaderBox{
    background-color: white;
    padding: 8px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    
}
.LoaderBackdrop.MuiBackdrop-root{
    background-color: #fff7 !important;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}
