.customDatePicker .MuiInputBase-colorPrimary{
    height:40px;
    border:1px solid #8f909a;
    font-size: 14px;
}
.customDatePicker{
    margin-top:0px;
    width:100%;
}
.customDatePicker .MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error input{
    border: none;
}

.customDatePicker .MuiOutlinedInput-notchedOutline{
    border: 1px;
}

.customDatePicker .Mui-focused{
    box-shadow: 0px 0px 5px 2px rgba(63, 150, 221, 0.5) !important;
    border: 1px solid #3f96dd !important;
}
.customDatePicker .MuiOutlinedInput-root.Mui-error{
    border: 1px solid #D70015 !important;
}