.beneficaryHeading {
  color: #1d2026;
  font-size: 16px;
  width: 100%;
}

.beneficiaryInput {
  display: flex;
  align-items: baseline;
}

.tableHeading{
  font-weight: bold;
}

.tableSubHeading{
  font-weight: normal;
  font-size: 14px;
}

.justifyContent {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.inputModel{
  height: 25px;
  padding: 2px;
  width: 100%;
  font-size: 16px;
  text-align: center;
}

.costPerBeneficiary{
  font-size: 12px;
}

.inputbox{
  height: 2.5rem;
}

.totalData{
  position: relative;
}

.total{
  position: absolute;
  bottom: 15px;
  right: 12px;
  font-size: 14px;
}

.inputDisabled{
  cursor: not-allowed;
  pointer-events: all;
  color: rgb(170, 170, 170);
}

.margin-left{
  margin-left: 10px;
}

