.mainDiv {
  background-color: #ffffff;
}

.mainLoginDiv {
  background-color: var(--loginBackground);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* White */
  background: #ffffff;
  /* outline */
  border: 1px solid #c5c9d0;
  border-radius: 15px;
  padding: 50px 0px;
}

.creteCard {
  justify-content: left;
}

.privacyText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #1d2026;
  margin-top: 18px;
}

.addunderline:hover {
  text-decoration: underline;
}
.alreadyText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #1d2026;
}

.crteAcntCard {
  height: 800px;
  width: 700px;
  background: #ffffff;
  border: 1px solid #c5c9d0;
  border-radius: 15px;
}

.crteText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #1d2026;
}

/* -----------------------------------------Login Page Styles-------------------------------------------- */

/* --------Main Container Outer Grid----   */
.container-body {
  /* height: 100vh; */
  background-color: #eef0f5;
  font-size: 100%;
  width: 100%;
  overflow: auto;
}

/* ---------------Card-------------  */
.container-card {
  border-radius: 15px;
  border-color: #c5c9d0;
  background-color: #ffffff;
  border: 1px;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* -----------------logo----------------  */

.loginLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 200px;
}

/* ---------------card title---------  */
.card-title {
  font-weight: 400;
  font-size: 1.375rem;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  color: #1d2026;
  font-family: Roboto;
}

/* ----------padding between grid items------- */
.grid-item {
  padding: 0.44rem;
  padding-left: 0px;
  padding-right: 0px;
}

/* ---------------text-field-----------  */
.input-text {
  height: 2.5rem;
  width: 31.25rem;
}

/* ---------------forgot password text------  */
.left-side {
  position: relative;
  top: 1.3rem;
  float: right;
  cursor: pointer;
  z-index: 999;
}

.passwordInput {
  border-radius: 4px;
  background-color: #fcfcfb;
  border: 1px solid #8f909a;
  font-size: 16px;
  margin-top: 5px;
}

.passwordInput .MuiInputBase-inputAdornedEnd {
  border-radius: 4px;
  background-color: #fcfcfb;
  border: none;
  font-size: 14px;
}

.forgot-link {
  font-size: 12px;
  color: #007eb0;
  font-family: Roboto;
  cursor: pointer;
}

/* -----------------policy text-------------- */

.policy-text {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 2rem;
  font-family: Roboto;
  color: #1d2026;
}
.policy-textfp {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 1px;
  text-align: center;
  font-family: Roboto;
  color: #1d2026;
}

.decoration {
  color: #007eb0;
  cursor: pointer;
}

.height-20.MuiGrid-root {
  height: 20px;
}

/* -----------divider-text------------- */
.divider {
  margin-bottom: 1.56rem;
}

.divider-text {
  font-size: 14px;
  font-family: Roboto;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #5c5f67;
}

/* ---------last-text---------  */
.bottom-text {
  margin-top: 20px;
  text-align: center;
}

.text-font {
  color: #007eb0;
}

.passwordHelpText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #5c5f67;
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: 21px;
}

.signInText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #007eb0;
  cursor: pointer;
}

.textbtm {
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 1px;
  font-weight: 400;
}

.lastText {
  color: #1d2026;
}

/* ---------------------*Media Queries*--------------------- */
@media (max-width: 1024px) {
  .grid-item {
    padding: 0.5rem;
  }

  .bottom-text {
    margin-top: 20px;
    text-align: center;
  }
}

@media (min-height: 500px) and (max-height: 800px) {
  .grid-item {
    padding: 0.25rem;
  }

  .policy-text {
    margin-bottom: 0.25rem;
  }

  .card-title {
    font-size: 1.1rem;
  }

  .divider {
    margin-bottom: 0.25rem !important;
  }

  .bottom-text {
    text-align: center;
  }

  .textbtm {
    font-size: 0.7rem;
  }
}

@media (min-height: 400px) and (max-height: 600px) {
  .grid-item {
    padding: 0.25rem;
  }

  .policy-text {
    margin-bottom: 0.25rem;
  }

  .container-card {
    border-radius: 15px;
    border-color: #c5c9d0;
    background-color: #ffffff;
    border: 1px;
    /* padding: 1rem 1.25rem;
    width: 25.25rem; */
  }

  .card-title {
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
  }

  .divider {
    margin-bottom: 0.25rem !important;
  }

  .bottom-text {
    text-align: center;
  }

  .textbtm {
    font-size: 0.7rem;
  }

  .divider-text {
    font-size: 12px;
  }
}

@media (min-height: 800px) and (max-height: 1050px) {
  .container-card {
    border-radius: 15px;
    border-color: #c5c9d0;
    background-color: #ffffff;
    border: 1px;
  }

  .card-title {
    font-weight: 400;
    font-size: 1.9rem;
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 1.15rem;
  }

  .divider {
    margin-bottom: 0.05rem !important;
  }

  .bottom-text {
    text-align: center;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 7px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #e2e3e6;
  border-radius: 10px;
}

.font-size {
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 2;
  margin-top: 20px;
}

.conditionsButton {
  display: flex;
  justify-content: flex-end;
  padding: 26px;
}

/* TEMPLATES CLASS */

.upperParagraph {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1px;
  color: #1d2026;
}

/* TEMPLATE TERMS OF USE  */

.termsParagraph {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1px;
  color: #1d2026;
}

.termsHeadeing {
  text-decoration: underline;
  font-weight: 700;
}

.termsContent {
  display: flex;
}
.termsContent2 {
  display: flex;
  margin-left: 15px;
}
.termsinnerpara {
  margin-left: 15px;
}
.terms {
  margin-left: 20px;
}

/* CREATE ACCOUNT  */

.createDivider {
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
}
.addBeneficiaryDivider {
  margin-top: 25px;
  margin-bottom: 40px;
  margin-left: 0px;
  margin-right: 0px;
}

.formCard {
  padding-top: 20px;
}

a {
  text-decoration: none;
  color: #007eb0;
  cursor: pointer;
}

.passwordHelp {
  align-self: self-end;
}

.copyrightText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  color: #1d2026;
}

.helpText {
  display: flex;
  justify-content: center;
  color: #007eb0;
  font-size: 14px;
}

.privacyPadding {
  padding: 6.5vh 1vh 0px 6.5vh;
}

.sidebarHelpContent {
  display: flex;
  justify-content: center;
}

.copyrightDiv {
  text-align: center;
}

/*Forget password*/
.forgetPassword-title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1px;
  color: #1d2026;
  margin-top: 30px;
}

.linkEmail-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  color: #1d2026;
  margin-top: 5px;
  margin-bottom: 30px;
}

.mt-44 {
  margin-top: 44px;
  margin-bottom: 12px;
}

.reset-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 1px;
  color: #1d2026;
  margin-top: 5px;
  margin-bottom: 40px;
}

.helpContact {
  margin-top: 62px;
}

.createHelpContact {
  margin-top: 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
}

.already-block .MuiGrid-root {
  height: 20px;
}

/* Privacy policy modal */
.PrivacyPolicyModal .mainPrivacyPolicyCard {
  margin-top: 0px;
  overflow: auto;
  height: 81vh;
}
.MuiStepLabel-iconContainer {
  padding-right: 1.67vw;
}
.privacyParagraph {
  text-align: justify;
}
.termsParagraph {
  text-align: justify;
}
@media (max-width: 1300px) {
  .privacyPadding {
    padding: 3vh 1vh 0px 4vh;
  }

  .textLabel {
    font-size: 14px;
  }
}
@media (max-width: 1100px) {
  .privacyPadding {
    padding: 3vh 1vh 0px 2vh;
  }

  .textLabel {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .privacyPadding {
    padding: 3vh 1vh 0px 1vh;
  }
}

@media (max-width: 1100px) and (min-width: 700px) {
  .grid-container {
    padding-top: 5px;
    height: 400px;
    overflow-y: scroll;
    padding-right: 10px;
  }

  .grid-container::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e2e3e6;
    border-radius: 10px;
  }
}

@media (min-height: 500px) and (max-height: 750px) {
  .createDivider {
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .formCard {
    padding-top: 0px;
  }
}

@media (min-height: 750px) and (max-height: 900px) {
  .createAccountBtn {
    margin-top: 15px;
  }

  .alreadyAccount {
    padding-top: 20px;
  }
}

@media (max-width: 1535px) {
  .creteCard {
    justify-content: center;
  }
}

.chosePlanTabs {
  height: 40px;
  padding-top: 10px;

}

.BillingSchemeSelection {
  border-bottom: 0.5px solid #c4c4c4;
  font-weight: 500;
  font-size: 20px;
  font-family: 'Roboto';
}

.chosePlanTabs button{
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: 'Roboto' !important;
}

.chosePlanTabs .css-1aquho2-MuiTabs-indicator {
  height: 3px;
  transition: none;
}

.chosePlanTabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
font-weight: 600 !important;

}
/* 
@media(min-width:700) and (max-width:1000) {
  
}
@media(min-width:1000) and (max-width:1400) {
  
}
@media(min-width:1400) and (max-width:1600) {
  
}
@media(min-width:1600) and (max-width:1800) {
  
} */
