.pendingIcon {
  height: 74px;
  width: 74px;
  color: orange;
}

.downloadPara {
  font-size: 12px;
  color: grey;
  text-align: center;
}
.paymentPendingIndex {
  z-index: 1999999999;
}

.paymentModalMessage{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 0 4px 0 4px;
  line-height: 36px;
  letter-spacing: 1px;
  color: #1d2026;
}

.paymentModalMessageConfirmation {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
}

.paymentPendingCard {
  width: 38.75vw !important;
  padding: 83px 14px !important;
}

.paymentPendingText {
  line-height: 30px !important;
}

.pendingSubText {
  font-weight: bold;
  color: grey;
}

.pendingPadding {
  padding-left: 50px !important;
  padding-right: 50px;
}
