.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
    color: #1d2026;
}

.tb-0 {
    background-color: white;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: white;

}

/* //////////////////// */
.outer-pd-device .MuiPaper-root.MuiDialog-paper {
    padding-top: 4.75vh;
    padding-bottom: 4.75vh;
    max-width: 700px;
    /* max-height: 947px; */
}
