.subscriptionHeading {
  display: flex;
  align-items: center;
}
.topHeadingBorder {
  padding-top: 3px;
}
.manageSubscriptionText {
  font-size: 24px;
  margin-left: 2px;
}
.manageBolo {
  font-weight: 700;
}
.topHeadingBorder {
  margin-bottom: 20px;
}

.updateBenNote{
  color: #007eb0;
  margin-top: 4px;
  font-size: 14px;

}

.updateBentotal{
  position: absolute;
  bottom: 7px;
  right: 12px;
  font-size: 14px;
}

.cancelSubscription {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #007eb0;
  cursor: pointer;
}

.noData {
  justify-content: center;
}

.nextBtnWidth {
  width: 100%;
}

.boxShadow {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
}

.specificheader{
  font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #1d2026;
}

.multi-line-header.MuiDataGrid-columnHeader{
  display: flex ;
  flex-direction: column;
}

@media (max-width: 1800px) and (min-width: 1536px) {
  .subscriptionText {
    font-size: 12px;
    letter-spacing: 0px;
  }
}

@media (max-width: 715px) {
  .manageSubscriptionText {
    font-size: 16px;
  }
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 2px solid #ddd;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
  width: 70%;
  /* margin-left: 10px; */
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 26px;
  cursor: pointer;
  margin: 0;
  position: relative;
  /* font-size: 16px; */
}

.number-input button:after {
  display: inline-block;
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f077";
  transform: translate(-50%, -50%) rotate(180deg);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(0deg);
}

.number-input input[type="number"] {
  font-family: sans-serif;
  border: solid #ddd;
  border-width: 0 2px;
  /* font-size: 18px; */
  height: 26px;
  text-align: center;
}

.beneficaryHeading {
  color: #1d2026;
  font-size: 16px;
  width: 50%;
}

.beneficiaryInput {
  display: flex;
  align-items: baseline;
 
}

.justifyContent{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.deviceBundleptag{
  margin-bottom: 0px;
}
.hrLine{
  margin-top: 30px;
}
.color{
  color: #3F96DD !important;
}

.hr {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #ccc !important;
}

.optionButton .MuiMenu-paper.MuiPaper-elevation{
  width: 215px !important;
}

.deactivateButton{
  height: 30px;
  font-size: 12px;
}

.footerNote{
  position: relative;
}

.note{
  position: absolute;
  bottom: 15px;
  font-size: 13px;
  margin-left: 8px;
}

/* PAYMENT STATUS MODAL  */
 
.OrderConformationContainer {
  padding: 8% 14%;
  text-align: center;
}

.PaymentModal .MuiPaper-root.MuiDialog-paper {
  width: 580px;
  max-width: 744px;
  overflow-y: scroll;
  background: #FFFFFF;
  border: 1px solid #C5C9D0;
  border-radius: 15px;
}

.primaryTextFont{
  margin-top: 20px;
}
.secondaryFont{
  font-size: 12px;
}

/* DEVICE TABLE STYLING */

.deviceList {
padding: 16px 0;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}

.deviceItem {
  margin-right: 10px;
  /* display: flex;
  justify-content: space-between;
  padding: 8px 0; */
}


.deviceInfo {
  flex-basis: 50%; 
  text-align: left;
  font-size:18px;
}
.headerItem{
  text-align: left;
  flex-basis: 50%; 
}

.primaryText .subsctionCancelBtn{
  font-size: 12px;
}

.supportModalConfirmation{
  display: flex;
  flex-direction: column;
}

.updateButton{
  background-color: #3F96DD;
  height: 40px;
  width: 100%;
}
.existingUserModal{
  padding: 20px 40px;
}

.support{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.existingMessage{
  font-size: 16px;
  line-height: 1.5;
}

.existingBtn{
  width: 160px;
  margin-top: 20px;
}

.lowerPara{
  margin-top: 20px;
  font-size: 14px;
}

.reviwCheckImg{
  height: 80px;
}

.marginTop{
  margin-top: 20px;
}

.deviceFontSize {
  font-size: 16px;
}

.reviewBtnCancel{

}

.reviewPayBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.fontIcon svg{
  font-size: 15px;
}

.riviewBold{
  font-weight: bold;
}

.marginTopBtn{
  margin-top: 20px;
}