/* Review and pay */

.reviewPayCard {
  padding: 84px 50px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #c5c9d0;
  border-radius: 15px;
  width: 744px;
}

.modifyButton {
  color: #3f96dd;
}
/* .inputInputBoxes{
    font-size: 10px;
}

.inputInputBoxes.Mui-focused{
    font-size: 10px; */
/* } */
.reviewDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.width-100 {
  padding: 2px 30px 2px 0px;
}
.width-100 .MuiInputBase-input {
  font-size: 12px;
}
.width-100 .MuiInputBase-input:focus {
  box-shadow: none;
}
.couponErrorText {
  color: #d70015;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 12px; */
  letter-spacing: 1px;
  margin-top: 0px;
}

.couponSuccessText {
  color: #41a353;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 12px; */
  letter-spacing: 1px;
  margin-top: 0px;
  margin-left: 2px;
}
.width-50 {
  padding: 10px 1px;
}

.width-50-onsuccess {
  padding: 10px 1px;
  padding-bottom: 0;
}

.width-50 .MuiInputBase-input {
  font-size: 12px;
}
.text1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
}

.text2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
}

/* Containers Padding  */

.around-margin {
  padding: 40px 50px;
}

.scroll-add {
  overflow-y: scroll;
  height: 77vh;
}

.tickIcon {
  position: absolute;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
}

.content-title {
  display: flex;
  flex-direction: row;
  /* text-align: center; */
}

.plan-title {
  /* font-family: Roboto; */
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #3f96dd;
  margin-left: 22px;
}

.select-plan {
  padding: 14px 44px 7px 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
}
.billing-Address-review {
  padding: 7px 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
}
.benf-info {
  padding-left: 44px;
  /* padding-right: 44px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
}

.text-premium {
  font-weight: 700;
  text-transform: capitalize;
  color: #1d2026;
}

.faded-text {
  color: #5c5f67;
}

.text-color {
  color: #1d2026;
}
.grid-margin {
  margin-right: 110px;
}

/* Inner Card Styling */
.pay-card {
  background-color: #fafafa;
  margin-left: 10px;
}

.product-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5c5f67;
}

.usd {
  font-size: 10px;
}

.pd-around {
  /* margin-left: 44px;
    margin-top: 14px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
}

.pr-text {
  /* margin-left: 44px;
    margin-top: 14px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #1d2026;
}
.reviewpr-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #1d2026;
}

.price-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #2e2e2e;
  /* margin-right: 10px; */
}

.flex-dr {
  display: flex;
}

.modifyLink {
  color: #3f96dd;
  font-weight: 400;
  font-size: 14px;
}
.inncerCardLast {
  display: flex;
  justify-content: flex-end;
}
.priceLine {
  display: flex;
  justify-content: flex-end;
}
.inerHrDivider {
  margin: 14px 0px;
}

.marginDefineTop {
  margin-top: 0px;
  margin-bottom: 0px;
}

.modifyMarginTop {
  margin-top: 20px;
  width: 20px;
}

.modifyMarginTopAlign {
  margin-top: 0px;
  width: 20px;
}

.modifyMarginTop .MuiButtonBase-root-MuiButton-root:hover {
  background-color: none !important;
}
.price-list {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  padding: 10px;
  padding-right: 0px;
}
.price-label-list {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  padding: 10px;
}
.couponAlign {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 899px) {
  .price-label-list {
    align-items: flex-start;
    padding-left: 0px;
  }
  .price-list {
    align-items: flex-end;
  }
}

/* .checkTickIcon{
    color: #fff;
    background-color: #3f96dd;
} */
.checkIcon .MuiIconButton-root {
  background-color: #3f96dd;
  color: #fff;
  border-radius: 0px 5px 5px 0px;
  padding: 0px;
  margin-left: 5px;
  height: 1.66em;
  width: 35px;
}
.checkIconDisable {
  color: #004976;
  border-radius: 5%;
  margin-left: 5px;
  height: 1.48em;
  width: 25px;
}
.total-list {
  text-align: end;
}

.customizeInput{
  display: flex;
  align-items: center;
}

.minusSign {
  font-weight: bold;
  font-size: 20px;
  margin-right: 4px;
  margin-top: 2px;
  cursor: pointer;
}
.addSign{
  font-weight: bold;
  font-size: 20px;
  margin-left: 4px;
  margin-top: 2px;

  cursor: pointer;

}

.next-btn {
  margin-top: 2.5vh;
}

/* divider margin  */
.margin-lr {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.inputModify {
  display: flex;
}

.nxt_Btn.primary.MuiButton-contained {
  width: 183px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.listOrder {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.reviewP-topBottom {
  padding: 40px 0px 30px 0px;
}
.subscriberM-top {
  margin-top: 5px;
}
.secondInnerCard {
  padding-top: 20px;
}
.tooltipParagrah {
  margin: 0px;
}
.taxImg {
  position: relative;
  top: 2px;
}
.content-inner-title {
  display: flex;
}
.icon {
  margin-left: 4px;
  vertical-align: middle;
}
.couponInput .MuiInputBase-input {
  -webkit-text-fill-color: #004976 !important;
  border: 1px solid #99cbff;
}
.inlineFlex {
  display: inline-flex;
}
.noMargin {
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 1199px) {
  .widthPara {
    width: fit-content;
  }
}
@media (max-height: 1050px) and (min-height: 800px) {
  .review-card {
    min-height: 400px;
  }

  .product-text {
    font-size: 14px;
  }
}

@media (max-height: 800px) and (min-height: 550px) {
  .review-card {
    min-height: 100px;
    margin: 10px;
  }

  .product-text {
    font-size: 12px;
  }
}

@media (max-width: 1500px) and (min-width: 1000px) {
  .product-text {
    font-size: 10px;
  }

  .inputModify {
    display: flex;
    flex-direction: column;
  }

  .inner-scroll-add {
    width: 98%;
    overflow: auto;
  }

  .pay-card {
    width: 100%;
  }
}

@media (max-width: 1000px) and (min-width: 600px) {
  .inputModify {
    display: flex;
    flex-direction: column;
  }

  .inner-scroll-add {
    width: 98%;
    overflow: auto;
  }

  .pay-card {
    width: 100%;
  }
}

@media (max-width: 1400px) {
  .width-100 .MuiInputBase-input {
    font-size: 10px;
  }

  .width-50 .MuiInputBase-input {
    font-size: 10px;
  }
}
