.alertClass{
border-radius: 5px;
}
.alertClass.MuiAlert-standardSuccess {
    background-color: #C6FFC5;
  
}
.alertClass.MuiAlert-standardError{
    background-color: #FFEDEA;
}
.MuiAlert-standardSuccess .MuiAlert-message{
    color: #248A3D;
}
.MuiAlert-standardError .MuiAlert-message{
    color:#D70015
}
.MuiAlert-standardSuccess .MuiAlert-action .MuiIconButton-root:hover{
    background: #00531D;
    color: white;
    border-radius: 3px;
}
.MuiAlert-standardError .MuiAlert-action .MuiIconButton-root:hover{
    background:#C93400;
    color: white;
    border-radius: 3px;
}
.MuiAlert-icon{
    display:none;
}
