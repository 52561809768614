/* =======================Base Plan Styling===================== */
.outer-padding .MuiPaper-root.MuiDialog-paper {
  padding-top: 4vh;
  padding-bottom: 4vh;
  max-width: 700px;
}

/* .jc-center{
  display: flex;
  justify-content: center;
} */

.input-padding .MuiDialogContent-root {
  padding: 0px;
  padding-bottom: 16px;
}

.MuiSelect-outlined.Mui-disabled {
  cursor: not-allowed;
}

.MuiInputBase-input.Mui-disabled {
  cursor: not-allowed;
}

.modal-title .MuiDialogTitle-root {
  padding: 0px;
  margin-bottom: 1.87vh;
  color: #1d2026;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: Roboto;
}

.modal-title .modalDivider.MuiDivider-root {
  margin: 0px;
}

.pd .MuiDialogContent-root {
  /* margin-top: 40px; */
}

.modalButton {
  /* margin-left: 32px; */
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.selectInput-title .inputTextLabel {
  left: 2px;
  letter-spacing: 1px;
}

.planDetails {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #007eb0;
}

.planDetails-title.labelClass.MuiInputLabel-root {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  top: -12px;
  left: 3px;
}

.cancel-icon {
  position: absolute;
  right: 0px;
}

.activeClass {
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #248a3d;
  background: #c6ffc5;
  border-radius: 4px;
  padding: 3px 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.DeactiveClass {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #d70015;
  background: #ffedea;
  border-radius: 3px;
  padding: 3px 5px;
}
.draftClass {
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #b25000;
  background: #ffdbc9;
  border-radius: 4px;
  padding: 3px 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
/* -------Preview Base Plan ---------------- */

.outer-pd .MuiPaper-root.MuiDialog-paper {
  padding-top: 5vh;
  padding-bottom: 5vh;
  /* min-width: 806px; */
}

.plan-box {
  border: 1px solid #006e28;
  border-radius: 15px;
}

.MuiDataGrid-columnHeaders{
  line-height: inherit !important;
}

.newCreatePlanModal .MuiPaper-root.MuiDialog-paper {
  max-width: 70vw !important;
  width: 70vw !important;
} 

.modal-padding.MuiModal-root {
  padding: 0px;
}
.base-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #1d2026;
}

.normal-font {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #1d2026;
}

.dollar {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
}

.numerals {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  /* position: relative;
  left: 15px;
  top: 0px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  letter-spacing: 1px;
  color: #1d2026;
}

.subscript-font {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #1d2026;
}

.text-premium-h {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
}

.normal-fontText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
}

.text-display {
  display: flex;
  align-items: center;
}

.checkboxBox {
  color: #c5c9d0;
}

.sliceWidth p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 177px;
}

.inputBox{
  width: 45px;
  height: 20px;
  padding: 2px;
  text-align: center;
}

.fontIcon svg{
  font-size: 15px;
}

.richEditor  .ql-toolbar.ql-snow{
font-family: "Roboto" !important;
border-top-right-radius: 8px !important;
border-top-left-radius: 8px !important;
}
.richEditor .ql-container.ql-snow {
  font-family: "Roboto" !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }


.richTextError .ql-toolbar.ql-snow{
  border-top: 1px solid red !important;
  border-left: 1px solid red !important;
  border-right: 1px solid red !important;

}

.richTextError .ql-container.ql-snow {
  border-bottom: 1px solid red !important;
  border-left: 1px solid red !important;
  border-right: 1px solid red !important;
}

.extraDetailsFont {
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 700;
  position: relative;
  top: -90px;
  left: 75%
}
.rightJustified {
  display: flex;
  justify-content: end;
}

.newPartnerCreate .MuiPaper-root.MuiDialog-paper {
  max-width: 55vw !important;
  width: 55vw !important;
}

@media screen and (min-width: 992px) {
  .extraDetailsFont {
    left: 72%;
  }
}

@media screen and (min-width: 1100px) {
  .extraDetailsFont {
    left: 76%;
  }
}

@media screen and (min-width: 1280px) {
  .extraDetailsFont {
    left: 76%;
  }
}

@media screen and (min-width: 1356px) {
  .extraDetailsFont {
    left: 77%;
  }
}

@media screen and (min-width: 1440px) {
  .extraDetailsFont {
    left: 78%;
  }
}

@media screen and (min-width: 1640px) {
  .extraDetailsFont {
    left: 80%;
  }
}

@media screen and (min-width: 1920px) {
  .extraDetailsFont {
    left: 82%;
  }
}


/* .planDisplayCard {
  border: 1px solid #C5C9D0;
  box-shadow: 0px 1px 5px 0px #0000001A;
border-radius: 15px;
} */

/* .previewModal {
  max-width: 540px;
} */