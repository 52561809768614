.searchIcon {
  font-size: 24px;
  margin-left: auto;
  color: #ffffff;
}
.searchIconBtn {
  background: #3f96dd;
  cursor: pointer;
  border-radius: 5px;
  height: 100%;

}
.searchIconBtn:disabled{
  cursor: not-allowed;
}
.searchIconBtn:hover{
  background-color:#1565c0;
}

.searchTextStyle .MuiInputBase-input {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #5C5F67;
}