.history-main-title {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 2px;
  color: #1d2026;
}
.history-backIcon-pd {
  margin-right: 20px;
  cursor: pointer;
}

.otpMessage{
  font-size: 13px;
  position: relative;
  top: 20px;
  color: #3f96dd;
}
/* .history-backIcon-pd:hover {
  margin-right: 0px;
  width: 33px;
  background-color: red;
} */

.invoicecss .MuiDataGrid-columnHeaderTitle {
  white-space: break-spaces;
  text-align: center;
}

.hyphen{

}

@media (max-width: 900px) and (min-width: 500px) {
  u .history-main-title {
    display: flex;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    color: #1d2026;
  }

    .history-main-title {
        display: flex;
        align-items: center;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 1px;
        color: #1d2026;
      }

    .history-backIcon-pd {
        margin-top: 5px;
    }

}

.downloadInvoiceHeader .MuiDataGrid-columnHeaderTitle{
    white-space: break-spaces;
    text-align: center;
}


/* background-color:#edf4ff; */
