.inputTextLabel {
  position: absolute;
  top: -21px;
  left: 0px;
  font-size: 14px;
  color: #1d2026 !important;
  font-family: Roboto;
  transform: none;
}

.MuiMenu-list{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-width: 130px;
}

.selectInput {
  height: 40px;
  margin-top: 2px;
  border:1px solid #8F909A;
  font-size: 14px;
  color: #1D2026;
}

.selectInput.Mui-focused {
  border:none;
}

.selectInput .MuiOutlinedInput-notchedOutline {
  border: 0px;
}
.inputTextLabel:focus {
  color: #1d2026;
}
.inputTextLabelError.Mui-error {
  color: #d70015 !important;
}
.inputTextLabel .MuiFormLabel-asterisk {
  color: #d70015;
}

.selectInput.Mui-focused{
  box-shadow: 0px 0px 5px 2px rgba(63, 150, 221, 0.5) !important;
  border:1px solid  #3F96DD;
}

/* .selectInput.Mui-error.Mui-focused{
  border: none;
  box-shadow: none;
  color: none;
} */


.inputTextLabel.Mui-disabled {
  color: #8f909a !important;
}
.selectInput.MuiOutlinedInput-input.Mui-disabled {
  color: #8f909a;
}
.MuiMenu-paper .MuiListItemText-root span.MuiListItemText-primary {
  white-space: break-spaces;
}
.MuiMenu-paper .MuiButtonBase-root {
  justify-content: space-between;
  /* color: #1D2026; */
  font-size: 14px;
  padding: 11px;
}
.MuiMenu-paper .MuiButtonBase-root:hover {
  background-color: #edf4ff;
}

.placeholderOption{
  display: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.selectInput {
  border: 1px solid #d70015;
}

.MuiInputBase-root.MuiOutlinedInput-root{
  height: 40px;

}
.filterStyle {
  height: 40px;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
  background: #CFE5FF;
  border-radius: 5px;
  margin-top: 0px !important;
  border: 0px;
}

.filterStyle .MuiOutlinedInput-input {
  padding: 0px;
}

.filterStyle .MuiOutlinedInput-input span {
  color: #1D2026 !important;
}

.filterMenuStyle ul li{
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  white-space: break-spaces;
  word-break: break-word;
  font-size: 12px;
  color: #1D2026;
}
.filterMenuStyle ul li:last-child {
  border: none;
  font-size: 14px;
  color: #1D2026;

}
.filterMenuStyle ul li:first-child {
  display: none;
  font-size: 14px;
  color: #1D2026;
}

.filterMenuStyle::-webkit-scrollbar{
  width: 0px;
}

.dropdownMenuStyle ul li{
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  white-space: break-spaces;
  word-break: break-word;
  font-size: 14px;
}
.dropdownMenuStyle ul li:last-child {
  border: none;
}
.dropdownMenuStyle ul li:first-child {
  display: none;
}
.dropdownMenuStyle::-webkit-scrollbar{
  width: 0px;
}

