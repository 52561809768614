.modalDivider.MuiDivider-root{
    margin: 0px 20px;
}

.input-padding .MuiDialog-paperScrollPaper{
    max-height: calc(100% - 120px);
}

.modalCloseIcon:hover{
    background-color: #edf4ff;
}

.modalCloseIcon:hover .closeIcon{
    color: #3f96dd;
}

/* --------------------------------------------*/

.MuiDialog-paperScrollPaper{
    overflow: hidden;
}