/* ---------------billing-Shiiping-----------  */
.billingShippingHeader {
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1D2026;
}

.billingHeading {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.p-TopBottom{
  padding: 50px 0px 8px 0px;
}
.billingDivider{
  margin-top: 30px;
}

.nextBtn{
  margin-top: 35px;
  margin-bottom: 50px;
}
.mt-40{
  margin-top: 40px;
}
.selectState{
  top: 12px;
}
.selectInputAlign{
 align-self: self-end;
}
.stateMarginTop{
  margin-top: 10px;
}

@media (max-width:900px) and (min-width:500px) {
  .billingHeading {
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 6px;
  }

}

