/* PRIVACY POLICY */

.boxConatiner {
    background-color: #f0f1f4;
    padding: 0px 48px 0px 48px !important;
    height:  90vh;
    overflow: scroll;
}
.padding-35px{
    padding:0px 35px 35px 35px;
}

@media (max-width: 1000px) {
    .boxConatiner {
        padding: 0px 25px 0px 25px !important;
    }
}

@media (min-width: 1101px) and (max-width: 1600px) {
    .mainBoxcContainer {
        margin-bottom: 4vh !important;
    }
}

@media ((min-width:900px) and (max-width: 1100px)) {
    .mainBoxcContainer {
        margin-bottom: 18vh !important;
    }
}

@media ((max-width: 899px)) {
    .mainBoxcContainer {
        margin-bottom: 12vh !important;
    }
}
.boxContainerWoPadding {
    background-color: #f0f1f4;
    padding: 0px 38px 14px 38px !important;
    height:  90vh;
    overflow: scroll;
}

.stepperItem {
    display: flex;
    justify-content: center;
}

.textLabel {
    font-size: 18px;
    color: #5c5f67;
}

.stepperGridCotainer {
    /* min-height: calc(100vh - 10.48vh); */
    /* margin-top: 10.48vh; */
    background-color: white;
    width: 100vw;
}

.MuiStepLabel-vertical{
    margin-bottom: 6.55vh;
}

.MuiStepLabel-iconContainer.Mui-completed{
    color: #3F96DD !important;
}

.MuiStepLabel-iconContainer.Mui-active{
    color: #5C5F67 !important;
}

.MuiStepLabel-iconContainer{
    color: #5C5F67 !important;

}

.steeperLineHeight {
    line-height: 6;
}

.helpContact {
    font-size: 14px;
    color: #007eb0;
    margin-bottom: 10px;
}

.fphOrigin {
    font-size: 14px;
    color: #1D2026;
}

.stepperSidebarBottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    position: absolute;
    bottom: 4vh  ;
}

.privacyCardText {
    font-size: 24px;
    color: #3f96dd;
    font-weight: 900;
}

.padding-20px {
    padding: 35px;
}

.policyConditions1 {
    overflow-y: scroll;
    margin-top: 30px;
    padding-right: 20px;
}

.MuiStepConnector-root {
    display: none;
}

.stepDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #5C5F67;
}
.PrivacyPolicyModal .MuiDialog-paperScrollPaper {
    max-width: 46vw;
  }

.MuiStepLabel-label.Mui-completed .textLabel {
    color: #3F96DD;
    font-weight: 700;
}

.MuiStepLabel-label.Mui-active .textLabel {
    color: #1D2026;
    font-weight: 700;
}

.MuiStepLabel-root{
    align-items: flex-start;
}

@media (min-height:400px) and (max-height:700px) {

    .steeperLineHeight {
        line-height: 4;
    }

    
    .MuiStepLabel-vertical{
        margin-bottom: 4.55vh;
    }

    .primary.MuiButton-contained {
        height: 40px !important;
    }

}

@media (min-height: 600px) and (max-height: 800px) {
    .conditionsButton {
        margin-bottom: 8px;
    }

}

@media (max-width:800px) and (min-width:500px) {

    .privacyCardText {
        font-size: 20px;
    }

    .policyConditions1 {
        margin-top: 10px;
        padding-right: 5px;
    }

    .padding-20px {
        padding: 14px;
    }
}

@media (max-width: 1000px) and (min-width: 800px) {

    .privacyCardText {
        font-size: 22px;
    }

    .policyConditions1 {
        margin-top: 15px;
        padding-right: 8px;
    }

    .padding-20px {
        padding: 20px;
    }
}

@media (max-width: 1800px) and (min-width: 1400px) {

    .privacyCardText {
        font-size: 24px;
        color: #3f96dd;
        font-weight: 900;
    }

    .padding-20px {
        padding: 35px;
    }

    .policyConditions1 {
        overflow-y: scroll;
        margin-top: 30px;
        max-height: 57vh;
        padding-right: 20px;
    }

    .font-size {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 2;
        margin-top: 35px;
    }

    .conditionsButton {
        margin-bottom: 20px;
    }

}
@media (max-width:1199px){
    .PrivacyPolicyModal .MuiDialog-paperScrollPaper {
        max-width: 75vw;
      }
}




 /* PRIVACY POLICY TEMPLATE  */

    .privacyParagraph {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 1px;
      color: #1d2026;
    }
    .policyDetails{
        margin-left: 25px;
    }
    .policyNo{
        font-weight: 700;
    }
    .innerContent{
        display: flex;
    }
    .smallContent{
        margin-left: 15px;
    }
    .dott{
        font-size: 30px;
    }
    .smallDott{
        font-size: 26px;
    }
    .dottContent{
        display: flex;
        align-items: center;
    }
    .termsofuse{
        text-decoration: underline;
    }


    /* TERMS OF USE  */

    .termsParagraph {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 1px;
      color: #1d2026;
    }
  
    .termsHeadeing{
      text-decoration: underline;
      font-weight: 700;
    }
  
    .termsContent {
      display: flex;
    }
    .termsContent2{
        display: flex;
        margin-left: 15px;
    }
    .termsinnerpara{
        margin-left: 15px;
    }
    .terms {
      margin-left: 20px;
    }
    
  