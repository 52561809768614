.addBeneficaryBtn {
    width: 190px;
    margin-right: 20px;
}

.addFamilyMemberBtn {
    width: 210px;
}

.subscriberHeaderBtnText {
    font-size: 9px;
    letter-spacing: 0.5px;
}

.subscriberHeaderContainer {
    display: flex;
}
.userGuideHeading{
    font-size:18px;
    font-weight: bold;
    color: rgb(104, 100, 100);
}
.m-30 {
    margin-bottom: 30px;
}

.m-10 {
    margin-bottom: 10px;
}

.w699-addBeneficaryBtn {
    width: 46% !important;
    margin-right: 22px;
}

.w699-addFamilyMemberBtn {
    width: 47% !important;
}
.subsButtonContainer{
    text-align: end;
}

.cursor{
    cursor: pointer;
    margin-left: 5px;
}

.userTypes{
    font-weight: bold;
    text-transform: capitalize;
    font-size: 18px;
}

.subtitleUsertype{
    font-size: 16px;
    letter-spacing:1px
}

.okkBtn{
    width: 130px;
}