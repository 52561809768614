.mainBox{
    /* height: 100vh; */
    background-color: #F0F1F4;
}

.muiAppBarHeader{
    position: sticky;
    top: 0;
    max-height: 112px;
    display: flex;
    height: 10.48vh;
    min-height: 56px;
    justify-content: center;
    padding: 24px;
    
}

.muiAppBarHeader{
    background-color: #fff;
    border-bottom: 5px solid #004A78;
    box-shadow: none;
}

.mainPage{
    background-color: #F0F1F4;
    height: 100vh;
}

@media (max-width:768px){
    .mainPage{
        padding: 20px;
    }
}

@media (max-width:1950px) and (max-height:800px){
    .muiAppBarHeader{
        max-height: 75px;
    }
}
