.list-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.role-descript {
  padding: 30px;
  background: #ffffff;
  /* border: 1px solid #f0f1f4; */
  /* box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.05); */
}
.roleCard.innerMainCard {
  min-height: auto;
}
.roles-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
  margin-bottom: 20px;
}

.roles-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #5c5f67;
}

.row-name {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
}

.radio-btn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
}

.text-field {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #8f909a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.text-field:target {
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #8f909a;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.asterick-desc {
  color: #d70015;
  margin-left: 3px;
}

.rolesHide {
  z-index: 999;
}

.radioButton.Mui-disabled.Mui-checked {
  cursor: not-allowed;
  pointer-events: all;
  color: #3f96dd;
}

.radioButton.Mui-disabled {
  cursor: not-allowed;
  pointer-events: all;
}
