.fam {
    color: #06377A;
    /* font-weight: bold; */
    letter-spacing: 1px;
}

.health {
    color: #EF483F;
    /* font-weight: bold; */
    letter-spacing: 1px;
}

.r {
    color: #06377A;
    font-size: 0.75em; /* Smaller font size */
    position: relative;
    top: -2px; /* Adjust this value for vertical alignment */
}