.selectInputBox {
  width: 200px;
}
.Manage-order-Container {
  margin-right: auto;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}
.manageOrderHeading {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  color: #1d2026;
}

.manageOrderDatePicker {
  background: #ffffff;
  border-radius: 4px;
  padding: 0px;
  height: 40px;
  width: 100%;
}

.manageOrderDatePicker .MuiInputBase-inputAdornedEnd {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #1d2026;
  padding: 8px 0px 8px 14px;

}
.manageOrderDatePicker .MuiInputLabel-outlined {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #8f909a;
  top: -5px;
}

.manageOrderDatePicker .MuiOutlinedInput-root.Mui-error {
  border: 1px solid #D70015
}
.manageOrderDatePicker .MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error input {
  border: none
}

.manageOrderDatePicker .MuiInputLabel-outlined:focus {
  color: 1px solid #8f909a;
}
.manageOrderDatePicker .MuiInputLabel-shrink {
  display: none;
}
.manageOrderDatePicker .MuiInputBase-colorPrimary {
  border: 1px solid #8f909a;
}
.manageOrderDatePicker .MuiOutlinedInput-notchedOutline {
  border: none;
}
.manageOrderDatePicker .MuiInputBase-root {
  height: 100%;
}

.importExportBtnText.primaryText {
  font-size: 11px !important;
}

.boldText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
  margin: 0;
}
.lightText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #5c5f67;
  margin: 0;
}
.mobileNoStyle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
  margin: 0;
}

.p-0 {
  margin: 0;
}

.DetailContainer {
  display: flow-root;
}

.statusPending {
  background: #ffdbc9;
  border-radius: 3px;
  padding: 3px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #b25000;
}

.statusInTransit {
  background:#E2E3E6  ;
  border-radius: 3px;
  padding: 3px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color:#5C5F67;
}

.statusDelivered {
  background: #c6ffc5;
  border-radius: 3px;
  padding: 3px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #248a3d;
}

.statusConfirmed {
  background:#CFE5FF;
  border-radius: 3px;
  padding: 3px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color:#3F96DD;
}

.statusPartiallyDelivered {
  background: #248a3d;
  border-radius: 3px;
  padding: 3px 9px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #fff;
}

.textWordBreak {
  word-break: break-word;
}

.stickyCell {
  position: sticky !important;
  background-color: white;
  right: 0;
  margin: 0;
  z-index: 100;
}
.orderSelectInput {
  margin-top: 0px !important;
  /* background-color: #CFE5FF; */
}


.helper-text{
    color: #D70015;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px
}

.redborder.MuiInputLabel-outlined{
  border: 1px solid #D70015;
}

