.selectInputBox {
    width: 200px;
  }
  
  /* EDIT ORDER SCREEN  */
  
  .main-title {
    margin-left: 5px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 2px;
    color: #1d2026;
  }
  
  .backIcon-pd {
    margin-right: 20px;
    cursor: pointer;
  }
  
  .sub-name {
    font-weight: 700;
  }
  
  .reviewPayCard {
    padding: 84px 0px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c5c9d0;
    border-radius: 15px;
    width: 744px;
  }
  
  .reviewDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .text1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #1d2026;
  }
  
  .text2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #1d2026;
  }
  
  .around-margin {
    padding: 40px 50px;
  }
  
  /* .scroll-add {
    overflow-y: scroll;
    height: 77vh;
  } */
  
  .tickIcon {
    position: absolute;
    left: 8.33%;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;
  }
  
  .content-title {
    display: flex;
    flex-direction: row;
    /* text-align: center; */
  }
  
  .plan-title {
    /* font-family: Roboto; */
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #3f96dd;
    margin-left: 22px;
  }
  
  .Last-Inner-Card {
    margin-top: 30px;
  }
  
  .select-plan {
    padding: 14px 44px 7px 44px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
  .billing-Address-review {
    padding: 7px 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
  .benf-info {
    padding-left: 44px;
    /* padding-right: 44px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
  }
  
  .text-pr {
    font-weight: 700;
    text-transform: capitalize;
    color: #1d2026;
    font-size: 14px;
    letter-spacing: 1px;
  }
  
  .faded-text {
    color: #5c5f67;
  }
  
  .grid-margin {
    margin-right: 110px;
  }
  
  /* Inner Card Styling */
  .pay-card {
    background-color: #fafafa;
    margin-left: 10px;
  }
  
  .product-text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #5c5f67;
  }
  
  .usd {
    font-size: 10px;
  }
  
  .pd-around {
    /* margin-left: 44px;
      margin-top: 14px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
  }
  
  .pr-text {
    /* margin-left: 44px;
      margin-top: 14px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
    color: #1d2026;
  }
  
  .price-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #1d2026;
    /* margin-right: 10px; */
  }
  
  .flex-dr {
    display: flex;
  }
  
  .mod-link {
    position: relative;
    top: 23px;
    left: 16px;
    color: #3f96dd;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .inncerCardLast {
    display: flex;
    justify-content: flex-end;
  }
  .priceLine {
    display: flex;
    justify-content: flex-end;
  }
  .inerHrDivider {
    margin: 14px 0px;
  }
  
  .price-list {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
    color: #1d2026;
  }
  
  /* .total-list {
    text-align: end;
  } */
  
  .next-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5vh;
  }
  
  /* divider margin  */
  .margin-lr {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .inputModify {
    display: flex;
  }
  
  .nxtBtn {
    width: 9%;
  }
  
  .flex-end {
    display: flex;
    justify-content: flex-end;
  }
  
  .listOrder {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .reviewP-topBottom {
    padding: 40px 0px 30px 0px;
  }
  .subscriberM-top {
    margin-top: 5px;
  }
  .second-Inner-Card {
    margin-top: 15px;
    /* margin-left:32px */
  }
  .tooltipParagrah {
    margin: 0px;
  }
  
  .middle-column {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #1d2026;
  }
  
  .content-inner-title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #1d2026;
  }
  
  .pr-list {
    text-align: end;
  }
  
  .normal-color {
    color: #1d2026;
  }
  .changeOrder.inputTextLabel{
    top:-39%
  }
  
  @media (max-width: 900px) and (min-width: 500px) {
    .button-margin {
      margin-top: 10px;
    }
  
    /* .main-title {
      margin-left: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 1px;
      color: #1d2026;
    } */
  
    .pr-list {
      text-align: end;
    }
  
    .middle-column {
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      color: #1d2026;
    }
  }