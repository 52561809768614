.ulList {
  gap: 10px;
  padding: 0;
}

/* .sidebarBox {
  /* padding-top: 10.48vh; */
/* } */

.liList:hover {
  background-color: #edf4ff;
  border-left: 5px solid #3f96dd;
  color: #3f96dd;
  cursor: pointer;
}
.liList {
  height: 80px;
  border-left: 5px solid #edf4ff;
}

.label {
  font-size: 18px;
}

.liList p {
  margin-left: 20px;
  text-decoration: none;
  font-size: 18px;
  color: #757780;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 20px;
  justify-content: space-between;
}
.logoutOption {
  margin-left: 20px;
  text-decoration: none;
  font-size: 18px;
  color: #757780;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 20px;
}
.liList:hover .logoutOption {
  text-decoration: none;
  font-size: 18px;
  color: #757780;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  color: #3f96dd;
  font-weight: 700;
}

.liList:hover p {
  color: #3f96dd !important;
  font-weight: 700;
}
.liList p:focus {
  text-decoration: none;
  font-size: 18px;
  color: #757780;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  color: #3f96dd;
  font-weight: 700;
}

.Mui-selected.liList p {
  text-decoration: none;
  font-size: 18px;
  color: #757780;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  color: #3f96dd;
  font-weight: 700;
}

.liList:hover {
  background-color: #edf4ff;
  border-left: 5px solid #3f96dd;
  color: #3f96dd;
  cursor: pointer;
}
.liList.Mui-selected {
  background-color: #edf4ff;
  border-left: 5px solid #3f96dd;
  color: #3f96dd;
  cursor: pointer;
}

.sideNavFooterContainer {
  text-align: center;
  font-size: 14px;
  color: #5c5f67;
}
.sideNavFooter {
  margin-top: auto;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #5c5f67;
  padding: 20px 2.7vw;
}
.MuiCollapse-wrapperInner {
  background-color: #e8e9eb9e;
}
.submenuItem {
  height: 60px;
}
.submenuItem a {
  margin-left: 30px;
  text-decoration: none;
  font-size: 16px;
  color: #757780;
  width: 85%;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 20px;
  justify-content: space-between;
}
.tooltipWidth{
  width: inherit;
}
@media (max-width: 1536px) {
  .sideNavFooter {
    padding: 10px 20px;
  }
}

@media (max-width: 999px) {
  .liList p {
    margin-left: 0px;
  }
  .logoutOption {
    margin-left: 0px;
  }
}
@media (max-width: 1400px) and (min-width: 1000px) {
  .liList p {
    margin-left: 10px;
  }
  .logoutOption {
    margin-left: 10px;
  }
}

@media (max-width: 1600px) and (min-width: 1400px) {
  .liList {
    height: 65px;
  }
  .logoutOption {
    height: 65px;
  }
}

@media (max-width: 1900px) {
  .liList {
    height: 70px;
  }
  .logoutOption {
    height: 70px;
  }
}

@media (max-width: 1400px) and (max-width: 1000px) {
  .liList {
    height: 60px;
  }
  .logoutOption {
    height: 60px;
  }
}
