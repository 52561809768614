.adminfullName {
  font-weight: 700;
  font-size: 24px;
  color: #1D2026;
  text-transform: capitalize;
}
.fullNameRole{
    font-size: 14px ;
    color: #757780;
}
.activeimg{
    margin-left: 25px;
}
.activeGrid{
    display: flex;
    align-items: center;
}
.topHeaderHeading{
    display: flex;
    align-items: center;
}
.activeSinceDate{
    margin-left: 20px;
}
.settingSubHeading {
    font-family: Roboto;
    font-size: 1.25em;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: left;
    color: #1D2026;
    text-transform: capitalize;
}
.subscriberDetail{
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
    color: #1D2026;
}
.buttonContainer{
    padding-bottom: 20px;
}
.tooltipParagrah{
    margin: 0px;
}
@media (max-width:1199px){
    .widthPara{
        width: fit-content;
    }
}
@media(max-width:620px){
    .fullNameRole{
        margin-left: 35px;
    }
}
