.primary {
  height: 40px;
  background: #3f96dd;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}
.primary:hover {
  box-shadow: none;
}

.secondary {
  height: 40px;
  background-color: #e8f1ff;
  border: 1px solid #c5c9d0;
  border-radius: 5px;
  box-shadow: none;
}
.btnwrapper {
    cursor: not-allowed;
}

.primary.Mui-disabled{
  opacity: 0.7;
  background: #3f96dd !important;
  pointer-events: none;
  cursor: not-allowed !important;
}
.secondary.Mui-disabled{
  opacity: 0.7;
  background: #e8f1ff !important;
  pointer-events: none;
  cursor: not-allowed;

}

.circularProgress{
  color: white;
}
.MuiCircularProgress-root{
  width: 26px !important;
  height: 26px !important;
}

.secondary:hover {
  background-color: #d3e1f7;
  box-shadow: none;
}
.secondaryText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  color: #5c5f67;
}

.primaryText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
}

.add {
  width: 14px;
  height: 14px;
}
.reset{
  width: 20px;
  height: 20px;
 fill: white;
 color: white;
}

.download{
  fill: white;
  color: white;
  width: 14px;
  height: 14px;
}
@media (max-width: 800px) {
  .secondaryText {
    font-size: 11px;
  }
}

.primary.MuiButton-root:focus,
.primary.MuiButton-root.Mui-focusVisible {
  background-color: #1976d2 ;
  box-shadow: none;
  outline: none;
}


.secondary.MuiButton-root.Mui-focusVisible {
  background-color: #d3e1f7;
  box-shadow: none;
  outline: none;
}
