
.confirmModal .MuiDialog-container .MuiPaper-root{
  min-height: 145px;
    min-width: 400px;
    max-width: 400px;
}
.profileText{
  display: flex;
  justify-content: flex-start;
  color: #000;
}

.divider-mp{
  padding: 0 !important;
  margin: 0 !important;
}
.headerRole .f-14{
  text-transform: capitalize;
}

.f-14{
  font-size: 14px;
}

.f-12{
  font-size: 12px;
  padding-top: 3px;
  color: #1D2026;
}

.headerBar{
  border-bottom: 5px solid #004A78;
}

.headerSignOut{
  font-size: 14px;
  cursor: pointer;
  color: #1D2026;
  letter-spacing: 1px;
  line-height: 20px;
}

.flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.confirmModal{
  min-height: 145px;
  min-width: 400px;
}
.logowithText{
  width: 250px;
}
.userIcon{
  margin-right: 24px;
}
.notificationIcon{
  margin-right: 30px !important;
}

@media (max-width:1600px){
  .logowithText{
    width: 230px;
  }
}
@media (max-width:1000px) and (min-height:800px) {
  .logowithText{
    width: 220px;
  }
}

@media (max-height:799px) {
  .logowithText{
    width: 180px;
  }
}