.tableHeader.MuiTableCell-root{
    font-weight: bold;
    line-height: 2.5rem;
    font-size: 14px;
}

.tableRow.css-177gid-MuiTableCell-root{
    line-height: 3.5rem;
    font-size: 14px;
    
}
.workinprogress{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    color: #004A78;
    letter-spacing: 2px;
    font-size: 20px;
    font-weight: 500;
}


