/* SELCET PLAN */

.selectPlan {
  min-height: 100%;
  /* height: 76vh; */
  /* overflow: auto; */
  justify-content: center;
  align-items: center;
  display: flex;
}

.basicAndPremiumPlans {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

.planCard {
  padding: 40px 50px 40px 50px;
  border-radius: 15px !important;
}

.basicPLansColumn {
  display: flex;
  flex-direction: column !important;
  height: 73px;
}

.couponColor {
  /* margin-top: 10px; */
  font-size: 12px;
  font-weight: 700;
  color: #248a3d;
}

.fontWeight {
  font-size: 18px;
  font-weight: 700;
}

.fontWeight40 {
  font-size: 40px;
  font-weight: 700;
  color: #1d2026;
}

.fontSize {
  font-size: 12px;
  color: #1d2026;
}

.checkPlans {
  display: flex;
  align-items: flex-start;
}

.planText {
  margin-left: 20px;
  color: #5c5f67;
}

.selectPlanCard2 {
  margin-top: 50px;
}

.selectBtn {
  margin-top: 50px !important;
}

.plansAmount {
  display: flex;
  justify-content: flex-end;
}

.currency {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  color: #1d2026;
  vertical-align: top;
}

.recurringAmount {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #1d2026;
}

.month {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #1d2026;
}

.bottomNote{
  position: fixed;
  bottom: 0px;
  font-size: 14px;
  /* left:20px; */
  background-color: #F0F1F4;
  margin: 0 !important;
  width: 98%;
  color: #3f96dd ;
  padding: 8px;
}
.radioLine{
  display: flex;
}

.planDetailsLine{
  display: flex;
  align-items: start;
}

.radioButton{
  top:-8px
}

.deviceLine{
  font-size: 12px;
  margin-left: 11px;
}

.previewPlanBox{
}

.countryCode {
  margin-bottom: -7px;
}
.chooseText{
  font-weight: bold;
  margin-bottom: 12px;
}
.radioPlanName{
  font-size: 18px !important;
  margin-left: 10px;
}
.supportBen{
  font-weight: bold;
}
.divider {
  margin-bottom: 0.65rem;
}

.slice {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remarkDetails {
  /* line-height: 1.7; */
  height: 123px;
}

.selectPlan-1200 {
  padding-left: 40px !important;
}
.noData {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #5c5f67;
}
.m-2 {
  margin-top: 2px;
}
.selectedPlanCard {
  border: 1px solid #3f96dd;
  box-shadow: 0px 0px 5px 0px #008cff;
}
.color{
  /* color: rgb(35, 60, 120); */
  color: #3f96dd !important;
}
.color1{
  color: black(239, 72, 62);
}
.planCardHeading{
  font-size: 22px;
}

@media (max-width: 1800px) and (min-width: 1500px) {
  .planCard {
    padding: 30px;
  }

  .fontWeight {
    font-size: 15px;
  }
  .planCardHeading{
    font-size: 22px;
  }
  
  .recurringAmount {
    font-size: 22px;
  }

  .plansAmount {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1500px) and (min-width: 1200px) {
  .planCard {
    padding: 20px;
  }

  .fontWeight {
    font-size: 16px;
  }
  .planCardHeading{
    font-size: 22px;
  }
  .recurringAmount {
    font-size: 22px;
  }

  .currency {
    font-size: 8px;
  }
}

@media (max-width: 1200px) and (min-width: 800px) {
  .basicAndPremiumPlans {
    display: flex;
    padding: 30px;
  }

  .planCard {
    padding: 30px;
  }
  .planCardHeading{
    font-size: 22px;
  }
  .recurringAmount {
    font-size: 20px;
  }

  .plansAmount {
    display: flex;
    justify-content: flex-end;
  }

  .month {
    font-size: 10px;
    margin-left: 5px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .basicAndPremiumPlans {
    display: flex;
    padding: 30px;
  }

  .planCard {
    padding: 20px;
  }

  .fontWeight {
    font-size: 18px;
  }

  .recurringAmount {
    font-size: 22px;
  }

  .plansAmount {
    display: flex;
  }

  .month {
    font-size: 8px;
    margin-left: 5px;
  }

  .selectPlan-1200 {
    padding-left: 30px !important;
  }
}
