.labelClass {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #1D2026;
    -webkit-transform: none
}

.labelClass.Mui-focused {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #1D2026;
}

.labelClass.Mui-focused.Mui-error {
    color: #D70015 !important;
}

.labelClass .MuiFormLabel-asterisk {
    color: #D70015;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error input {
    border: 1px solid #D70015;
}

.MuiInputLabel-root.Mui-error {
    color: #D70015;
}

.helperClass {
    color: #D70015;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;

}

.passwordInfo {
    position: relative;
    left: 85px;
    width: fit-content;
    cursor:pointer;
    top:4px
}