.Manage-Coupon-Container {
    margin-right: auto;
}

.createCouponBtn {
    width: 202px !important;
}

.manageCouponHeading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 2px;
    color: #1D2026;
}

@media screen and (max-width: 799px) {
    .manageCouponHeading {
        font-size: 22px !important;
    }

    .createCouponBtn {
        width: 150px !important;
    }

    .createCouponBtnText {
        font-size: 13px;
    }
}

@media screen and (max-width: 699px) {
    .manageCouponHeading {
        font-size: 20px !important;
    }

    .createCouponBtn {
        width: 135px !important;
    }

    .createCouponBtnText {
        font-size: 11px;
    }
}

@media screen and (max-width: 630px) {
    .manageCouponHeading {
        font-size: 19px !important;
    }

    .createCouponBtn {
        width: 130px !important;
    }

    .createCouponBtnText {
        font-size: 10px;
    }
}

/* Create Coupon Modal */

.CreateEditCouponModal {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #1D2026;
}

.CreateEditCouponModal .MuiPaper-root.MuiDialog-paper {
    padding-top: 4.7vh;
    padding-bottom: 4.7vh;
    max-width: 700px;
}

.createModalCouponBtn {
    width: 186px;
}
